.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.container {
  display: flex;
  height: 100vh;
}

.left-panel {
  width: 35%;
  border-right: 1px solid black;
  padding: 20px;
  background-color: white;
  overflow-y: auto;
}

.right-panel {
  flex-grow: 1;
  padding: 20px;
}

h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 5px;
  display: block;
}

.city-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.time-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid black;
  background-color: white;
  border-radius: 5px;
  width: calc(100% - 20px);
  transition: background-color 0.3s ease-in-out;
}

.time-card-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.city-name {
  width: 45%;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.city-time {
  width: 45%;
  font-size: 14px;
  text-align: left;
}

/* Highlight effect when hovering over the map marker */
.highlight {
  background-color: #add8e6; /* Light Blue */
}

.remove-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.remove-button:hover {
  background-color: darkred;
}

/* Leaflet Map Styling */
.leaflet-container {
  height: 100%;
  width: 100%;
}
